import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { DefaultHeader } from "components/headers/default-header"
import { Seo } from "components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import MapContainer from "components/map-container"
import { graphql } from "gatsby"

const items = [
  { title: "会社名", content: "株式会社X-HACK" },
  {
    title: "所在地",
    content: "〒107-0062 東京都港区南青山3-15-9 MINOWA表参道 3F-251",
  },
  { title: "代表取締役", content: "松田 信介" },
  { title: "資本金", content: "1,000,000円" },
  { title: "設立", content: "2018年3月14日" },
  {
    title: "事業内容",
    content: `Twitterインスタントウィン「PARKLoT」の開発、運営\nインターネットサービス事業\nコンサルティング事業`,
  },
  {
    title: "アクセス",
    content: "東京メトロ銀座線 半蔵門線「表参道」A4出口より徒歩3分",
  },
]

const Company = ({ location, data }) => {
  const {
    allMemberJson: { nodes: members },
  } = data
  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="会社概要"
          pagepath={location.pathname}
          pagedesc="会社概要 | PARKLoT(パークロット)は「月額制」で「何回でも」実施できるTwitterキャンペーンツールを提供しています。"
        />
        <main className="information">
          <section className="information__heading py-10 px-4 md:pt-14 md:pb-16 md:px-0">
            <div className="container">
              <h1 className="text-white font-bold">
                企業理念・会社情報・メンバー
              </h1>
            </div>
          </section>
          <section className="information__philosophy bg-white">
            <div className="container py-10 px-4 md:py-14 md:px-0">
              <h2 className="text-xl md:text-2xl text-title py-2 pl-6 mb-6 relative md:pl-8 md:mb-12">
                企業理念
              </h2>
              <h3 className="text-title font-bold mb-4 md:mb-6">
                ITの力で社会をエンパワーする
              </h3>
              <p className="text-text md:text-xl">
                我々のミッションは、世の中で働くすべての人をエンパワメントすることです。
                <br />
                今や、リモートワークは当たり前になり、その結果として、情報共有・業務効率化のためのソフトウェアは、いままでよりも一層、世の中に必要とされるようになりました。
                <br />
                これから、更にネット社会が発展し、働く場所や時間、言語、国籍も限定されない社会が到来する可能性が高いです。
                <br />
                労働人口が減少する日本で、多様な人材が活躍できるよう、日本の会社にも大きな変革が迫られています。
                <br />
                我々は、ソフトウェアの提供を通じて、多様な働き方を可能にし、さらに働く人々をエンパワメントするための世の中の仕組みを整備していきます。
              </p>
            </div>
          </section>
          <section className="information__about bg-white">
            <div className="container py-10 px-4 md:py-14 md:px-0">
              <h2 className="text-xl md:text-2xl text-title py-2 pl-6 mb-6 relative md:pl-8 md:mb-12">
                会社情報
              </h2>
              <table className="border-collapse w-full border-t border-lightgrey border-b mb-6 md:mb-12">
                <tbody>
                  {items.map((item, index) => {
                    return (
                      <tr key={index} className="border-b border-lightgrey">
                        <th className="text-text block px-3 pt-5 pb-2 md:pt-6 md:pb-6 md:w-4/12 md:table-cell">
                          {item.title}
                        </th>
                        <td className="text-text whitespace-pre-wrap px-3 pb-5 block md:table-cell md:pt-6 md:pb-6">
                          {item.content}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <MapContainer />
            </div>
          </section>
          <section className="information__members bg-white">
            <div className="container py-10 px-4 md:py-14 md:px-0">
              <h2 className="text-xl md:text-2xl text-title py-2 pl-6 mb-6 relative md:pl-8 md:mb-12">
                メンバー
              </h2>
              <div className="flex w-full flex-col gap-14 md:flex-row md:flex-wrap md:gap-20">
                {members.map(member => {
                  return (
                    <div key={member.id} className="information__member-photo">
                      <GatsbyImage
                        image={getImage(member.image)}
                        alt={member.name}
                        className="mb-6 rounded h-60"
                      />
                      <p className="text-title text-sm mb-1">
                        {member.position}
                      </p>
                      <p className="font-bold text-title text-xl mb-2">
                        {member.name}
                      </p>
                      <p className="text-text">{member.comment}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
          <section className="information__cta bg-primary">
            <div className="container py-10 px-4 md:py-14 md:px-0">
              <h2 className="cta text-white text-center mb-4 font-bold">
                フォロワー獲得と
                <br />
                販促を加速させる
                <br />
                SNSキャンペーンツール
              </h2>
              <p className="text-white text-center md:text-xl mb-8">
                キャンペーンの企画内容などの
                <br className="block md:hidden" />
                ご相談に関しても、承っております。
                <br className="block md:hidden" />
                お気軽にご連絡ください。
              </p>
              <div className="rounded-lg bg-white py-6 px-4 md:p-10">
                <div className="m-auto mb-4 text-center w-11/12 md:w-7/12">
                  <img
                    layout="constrained"
                    alt="3分でPARKLoTの機能が分かる資料"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/company/comp_doc.png"
                  />
                </div>
                <div className="m-auto mb-8 md:mb-14 text-center">
                  <a
                    className="btn-features_contact border text-base md:text-2xl hover:text-accent hover:bg-white hover:border"
                    href="https://hub.park-lot.com/document-download"
                    target="_blank"
                    rel="noreferrer"
                  >
                    3分でPARKLoTの機能が分かる
                    <br className="block md:hidden" />
                    資料をダウンロード（無料）
                  </a>
                </div>
                {/* その他お問合せ */}
                <div className="flex flex-col gap-4 md:flex-row md:justify-between md:gap-14 items-stretch text-center">
                  <div className="rounded-lg bg-palegrey p-4 md:py-6 md:px-8 w-full flex items-center flex-col justify-center">
                    <h4 className="font-bold text-xl text-text mb-3">
                      お電話でのお問い合わせ
                    </h4>
                    <p className="mb-3">
                      <a
                        href="tel:0368210143"
                        className="text-2xl text-accent eng-num font-bold"
                      >
                        <FontAwesomeIcon
                          icon={faPhoneAlt}
                          className="text-accent inline-block mr-2"
                        />
                        050-3528-8481
                      </a>
                    </p>
                    <p className="text-xs text-text">
                      （9:00 - 19:00 / 土日・祝日除く）
                    </p>
                  </div>
                  <div className="rounded-lg bg-palegrey p-4 md:py-6 md:px-8 w-full">
                    <h4 className="font-bold text-xl text-text mb-3">
                      無料オンライン相談
                    </h4>
                    <a
                      className="bg-primary text-white font-bold py-3 border px-6 inline-block mb-3 rounded hover:text-primary hover:bg-white hover:border"
                      href="https://hub.park-lot.com/free-consulting"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      予約可能な時間を確認する
                    </a>
                    <p className="text-xs text-text">
                      弊社サービスとの協業、商品・
                      <br className="md:hidden" />
                      サービスのご提案につきましては、
                      <br />
                      <a
                        href="https://hub.park-lot.com/contact"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="underline text-accent"
                      >
                        こちらのフォーム
                      </a>
                      からお問い合わせください。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query GetMembers {
    allMemberJson {
      nodes {
        id
        name
        position
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        comment
      }
    }
  }
`

export default Company
